<template>
  <router-link :to="`/account/agency/${params.id}`"
               class="tour-company-menu"
               :class="params.outOfList ? 'tour-company-menu__out-of-list' : ''">
    <div class="tour-company-menu__wrapper">
      <Row>
        <Column>
          <div class="tour-company-menu__icon-wrapper">
            <img class="tour-company-menu__icon"
                 :src="params.logo"
                 alt/>
          </div>
        </Column>
        <Column class="tour-company-menu__title-holder"
                justify="center">
          <p class="tour-company-menu__title">{{params.title}}</p>
        </Column>
      </Row>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'TourCompanyMenu',
  props: {
    params: {},
  },
};
</script>
